module.exports = [{
      plugin: require('../../../../local-cache/custom/ece6a99d81ee033034768cd0386afeb08988d587012316c712d0e8523080cbc5/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-1GG2KC6CE2"],"pluginConfig":{"respectDNT":true,"head":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../local-cache/custom/ece6a99d81ee033034768cd0386afeb08988d587012316c712d0e8523080cbc5/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
